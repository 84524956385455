<template>

  <el-container>


    <el-header height="220">
      <el-select @change="groupChange"   clearable v-model="queryInfo.group" placeholder="请选择集团"
                 style="margin-left: 5px">
        <el-option
          v-for="item in allGroup"
          :key="item.id"
          :label="item.dgroup"
          :value="item.dgroup">
        </el-option>
      </el-select>

      <el-select v-if="queryInfo.group !== ''"  @change="coalChange" clearable v-model="queryInfo.coal" placeholder="请选择煤矿"
                 style="margin-left: 5px">
        <el-option
          v-for="item in allCoal"
          :key="item.id"
          :label="item.coal"
          :value="item.coal">
        </el-option>
      </el-select>

      <el-select v-if="queryInfo.coal !== '' && queryInfo.group !== ''"  @change="deptChange"  clearable v-model="queryInfo.dept" placeholder="请选择部门"
                 style="margin-left: 5px">
        <el-option
          v-for="item in allDept"
          :key="item.id"
          :label="item.dept"
          :value="item.dept">
        </el-option>
      </el-select>


      <el-row style="margin-top: 10px">
        <el-button type="primary" icon="el-icon-plus" @click="addDeptVisible = true">添加</el-button>

      </el-row>

    </el-header>

    <el-main>
      <el-table
        ref="deptTable"
        highlight-current-row
        v-loading="loading"
        :border="true"
        :data="deptInfos"
        :row-class-name="deptStatus"
        tooltip-effect="dark"
        style="width: 100%;" @selection-change="handleTableSectionChange">


        <el-table-column type="expand" >

          <template slot-scope="props">
            <div class="table-container">
            <el-table :data="props.row.depts" style="width: 100%"   :row-class-name="deptStatus" >

              <el-table-column type="expand" >
                <template slot-scope="props">
                  <div class="table-container1">
                    <el-table :data="props.row.deptVos" style="width: 100%" :row-class-name="deptStatus">
                      <el-table-column  prop="dept" label="部门名称" align="center"></el-table-column>
                      <el-table-column prop="num" label="存在人数" align="center"></el-table-column>
                      <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                          <el-tooltip class="item" effect="dark" content="删除信息" placement="top-start">
                          <el-button v-bind:disabled = "scope.row.num === 0?false:true" icon="el-icon-edit"
                                     size="mini"
                                     type="danger"
                                     @click="open2(props.row.dgroup,props.row.coal,scope.row.dept)"></el-button>
                          </el-tooltip>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </template>
              </el-table-column>
              <el-table-column  prop="coal" label="煤矿名称" align="center"></el-table-column>
              <el-table-column prop="num" label="存在人数" align="center"></el-table-column>
              <el-table-column label="操作" align="center" >
                <template slot-scope="scope">
                  <el-button type="primary" size="mini" icon="el-icon-edit" @click="addDeptsByGroup(props.row.dgroup,scope.row.coal)">添加部门</el-button>
                  <el-tooltip class="item" effect="dark" content="删除信息" placement="top-start">
                  <el-button   v-bind:disabled = "scope.row.num === 0?false:true" icon="el-icon-edit"
                    size="mini"
                    type="danger"
                    @click="open1(scope.row, props.row.dgroup)"></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>

            </el-table>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="dgroup" label="集团名称" align="center">
        </el-table-column>
        <el-table-column prop="num" label="存在人数" align="center">

        </el-table-column>


        <el-table-column label="操作" align="center" width="230px">
          <template slot-scope="scope" >
            <el-button type="primary"  size="mini"   icon="el-icon-edit" @click="addCoalByGroup(scope.row.dgroup)">添加煤矿</el-button>
            <el-tooltip class="item" effect="dark" content="删除信息" placement="top-start">
            <el-button  icon="el-icon-edit" v-bind:disabled = "scope.row.num === 0?false:true"
                       size="mini"
                       type="danger"
                       @click="open(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>

      </el-table>

      <!--分页-->
      <el-pagination style="margin-top: 25px"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.pageNo"
                     :page-sizes="[10, 20, 30, 50]"
                     :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>

    </el-main>


    <el-dialog title="新增集团信息" :visible.sync="addDeptVisible" width="40%" @close="resetAddQuForm" center>
      <el-card>
        <el-form :model="addDeptForm" ref="addDeptForm" :rules="addDeptFormRules">
          <el-form-item label="集团名称" label-width="120px" prop="dgroup">
            <el-input v-model="addDeptForm.dgroup" style="margin-left: 5px" ></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="quxiao" >取 消</el-button>
        <el-button type="primary" @click="addDept">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="新增煤矿信息" :visible.sync="addCoalVisible" width="40%" @close="resetAddQuForm" center>
      <el-card>
        <el-form :model="addDeptForm" ref="addDeptForm" :rules="addCoalFormRules">
          <el-form-item label="煤矿名称" label-width="120px" prop="coal">
            <el-input v-model="addDeptForm.coal" style="margin-left: 5px" ></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="quxiao" >取 消</el-button>
        <el-button type="primary" @click="addDept">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="新增部门信息" :visible.sync="addDeptsVisible" width="40%" @close="resetAddQuForm" center>
      <el-card>
        <el-form :model="addDeptForm" ref="addDeptForm" :rules="addDeptsFormRules">
          <el-form-item label="部门名称" label-width="120px" prop="dept">
            <el-input v-model="addDeptForm.dept" style="margin-left: 5px" ></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="quxiao" >取 消</el-button>
        <el-button type="primary" @click="addDept">确 定</el-button>
      </div>
    </el-dialog>


  </el-container>

</template>

<script>

import questionBank from "@/api/questionBank";


export default {
  name: "dept",

  data(){
    return{
      queryInfo: {
        //题目类型下拉款所选的内容
        'group':'',
        'coal':'',
        'dept': '',
        'pageNo': 1,
        'pageSize': 10
      },
      deptInfos:[],
      loading: true,
      allGroup: [],
      allCoal:[],
      allDept:[],
      addDeptVisible:false,
      addCoalVisible:false,
      addDeptsVisible:false,

      total: 0,
      selectionTable: [],
      group:{
        'dgroup':'',

      },
      coal:{
        'dgroup':'',
        'coal':'',

      },
      deleteDept:{
        dgroup:'',
        coal:'',
        dept:'',
      },


      addDeptForm:{
        dgroup:'',
        coal:'',
        dept:'',
      },
      addDeptFormRules:{
        dgroup: [
          {
            required: true,
            message: '请输入集团',
            trigger: 'blur'
          }
        ],

      },
      addCoalFormRules:{
        coal: [
          {
            required: true,
            message: '请输入煤矿',
            trigger: 'blur'
          }
        ],

      },
      addDeptsFormRules:{
        dept: [
          {
            required: true,
            message: '请输入部门',
            trigger: 'blur'
          }
        ],
      },

    }
  },

  created() {
    this.getDeptInfos(),
    this.getAllGroup()
  },

  methods:{
    getDeptInfos(){
      questionBank.getDeptInfos(this.queryInfo).then((resp) => {
        if (resp.code === 200) {
          this.deptInfos = resp.data.data
          this.total = resp.data.total
          this.loading = false

        }
      })
    },
    getAllGroup() {
      questionBank.getAllGroup().then((resp) => {
        if (resp.code === 200) {
          this.allGroup = resp.data
        }
      })
    },
    getAllCoal() {

      questionBank.getAllCoal(this.group).then((resp) => {
        if (resp.code === 200) {
          this.allCoal = resp.data
        }
      })

    },
    getAllDept(){
      questionBank.getAllDept(this.coal).then((resp)=>{
        if(resp.code === 200){
          this.allDept = resp.data
        }
      })
    },
    deptChange(val){
      this.getAllDept()
    },
    groupChange(val) {
      this.queryInfo.coal=""
      this.queryInfo.dept=""

      this.queryInfo.group = val
      this.getDeptInfos()
      this.group.dgroup = this.queryInfo.group
      if (this.queryInfo.group !== null) {
        this.getAllCoal()
      }


    },
    coalChange(val) {
      this.queryInfo.dept=""

      this.queryInfo.coal = val
      this.getDeptInfos()
      this.coal.dgroup = this.queryInfo.group
      this.coal.coal = this.queryInfo.coal
      if(this.queryInfo.coal != null && this.queryInfo.group !== null){
        this.getAllDept()
      }

    },

    //分页页面大小改变
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.getDeptInfos()
    },
    //分页插件的页数
    handleCurrentChange(val) {
      this.queryInfo.pageNo = val
      this.getDeptInfos()
    },
    //处理表格被选中
    handleTableSectionChange(val) {
      this.selectionTable = val
    },
    deptStatus(row,index){
     // console.log('sdada'+row.row.status)
        if(row.row.num !== 0){


          return 'success-row';
        }
        else
        {
          return 'warning-row';
        }

    },
    deleteDeptChangeByDgroup(row){
      if(row.num === 0){
        return this.$message('无法删除')
      }
      this.deleteDept.dgroup = row.dgroup
      questionBank.deleteDept(this.deleteDept).then((resp)=>{

        if(resp.code === 200){
          this.deleteDept.dgroup = ''
          this.deleteDept.coal = ''
          this.deleteDept.dept = ''
          this.getDeptInfos()
          this.$notify({
            title: 'Tips',
            message: '删除成功',
            type: 'success',
            duration: 2000
          })
        }
      })
    },
    deleteDeptChangeByDept(dgroup,coal,dept){
      this.deleteDept.dgroup = dgroup
      this.deleteDept.coal = coal
      this.deleteDept.dept = dept
      questionBank.deleteDept(this.deleteDept).then((resp)=>{

        if(resp.code === 200){
          this.deleteDept.dgroup = ''
          this.deleteDept.coal = ''
          this.deleteDept.dept = ''
          this.getDeptInfos()
          this.$notify({
            title: 'Tips',
            message: '删除成功',
            type: 'success',
            duration: 2000
          })
        }
      })
    },

    deleteDeptChangeByCoal(row, grop){

        this.deleteDept.dgroup = grop
        this.deleteDept.coal = row.coal
       console.log(this.deleteDept)
        questionBank.deleteDept(this.deleteDept).then((resp)=>{

          if(resp.code === 200){
            this.deleteDept.dgroup = ''
            this.deleteDept.coal = ''
            this.deleteDept.dept = ''
            this.getDeptInfos()
            this.$notify({
              title: 'Tips',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
          }
        })

    },
    open(val) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteDeptChangeByDgroup(val)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    open1(val,var2) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteDeptChangeByCoal(val,var2)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    open2(val,val2,val3) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteDeptChangeByDept(val1,val2,val3)
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    quxiao(){
      this.addDeptVisible = false
      this.addCoalVisible = false
      this.addDeptsVisible = false
      this.addDeptForm.dgroup = '';
      this.addDeptForm.coal = '';
      this.addDeptForm.dept = ''
    },
    addDept(){
      this.$refs['addDeptForm'].validate((valid) => {
        if (valid)
        {
          questionBank.addDeptInfos(this.addDeptForm).then((resp)=>
          {

            if(resp.code===200)
            {
              this.addDeptVisible = false
              this.addCoalVisible = false
              this.addDeptsVisible = false
              this.getDeptInfos()
              this.$notify({
                title: 'Tips',
                message: resp.data,
                type: 'success',
                duration: 2000
              })
            }

          })

        }
        else if (!valid)
        {
          this.$message.error('请填写必要信息')
          return false
        }
      })

    },

    resetAddQuForm() {
      this.$refs['addDeptForm'].resetFields()
    },
    addCoalByGroup(dgroup){

      this.addDeptForm.dgroup = dgroup;
      this.addCoalVisible = true
    },
    addDeptsByGroup(dgroup,coal){
      console.log(dgroup + coal)

      this.addDeptForm.dgroup = dgroup;
      this.addDeptForm.coal = coal
      this.addDeptsVisible = true
    },


  },
}


</script>



<style>

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.table-container{
  margin-left: 200px; /* 设置左边距为20像素 */
}
.table-container1{
  margin-left: 400px; /* 设置左边距为20像素 */
}

.el-table__expand-icon{
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
/*2.展开按钮未点击的样式是加号带边框*/
.el-table__expand-icon .el-icon-arrow-right:before{
  content: "\e6d9";

  padding: 2px;
}

.el-table__expand-icon--expanded .el-icon-arrow-right:before{
  content: "\e6d8";
}





</style>
<style scoped lang="scss">
@import "../../assets/css/teacher/questionManage";
</style>
