import request from '@/utils/request'

export default {
  getBankHaveQuestionSumByType (queryInfo) {
    return request({
      url: '/public/getBankHaveQuestionSumByType',
      method: 'get',
      params: queryInfo
    })
  },
  deleteQuestionBank (ids) {
    return request({
      url: '/teacher/deleteQuestionBank',
      method: 'get',
      params: ids
    })
  },
  addQuestionBank (questionBank) {
    return request({
      url: '/teacher/addQuestionBank',
      method: 'post',
      data: questionBank
    })
  },
  getQuestionBank () {
    return request({
      url: '/teacher/getQuestionBank',
      method: 'get'
    })
  },
  addBankQuestion (params) {
    return request({
      url: '/teacher/addBankQuestion',
      method: 'get',
      params: params
    })
  },
  removeBankQuestion (params) {
    return request({
      url: '/teacher/removeBankQuestion',
      method: 'get',
      params: params
    })
  },
  getQuestionByBank (params) {
    return request({
      url: '/public/getQuestionByBank',
      method: 'get',
      params: params
    })
  },
  getUserInfo(queryInfo)
  {
    return request({
      url:'/admin/getUserInfos',
      method:'get',
      params: queryInfo
    })
  },
  getUser(queryInfo){
    return request({
      url:'/admin/getUser',
      method:'get',
      params: queryInfo
    })
  },
  getAllGroup()
  {
    return request({
      url:'/dept/getDgroup',
      method:'get'

    })
  },
  getAllCoal(group){
    return request({
      url:'/dept/getcoal',
      method:'post',
      data:group
    })
  },
  getAllDept(dept){
    return request({
      url:'/dept/getDpet',
      method:'post',
      data:dept
    })
  },
  getDeptInfos(queryInfo){
    return request({
      url:'/admin/getDeptInfos',
      method:'get',
      params:queryInfo
    })
  },
  deleteDept(deleteDept){
    return request({
      url:'/admin/deleteDept',
      method:'post',
      data:deleteDept
    })
  },
  addDeptInfos(depts){
    return request({
      url:'/admin/addDeptInfos',
      method:'post',
      data:depts
    })
  },
  getCompInfo(){
    return request({
      url:'/comp/getCompInfo',
      method:'get',

    })
  },
  getCompInfoImg(){
    return request({
      url:'/comp/getCompInfoImg',
      method:'get'
    })
  },
  updateCompInfo(profile){
    return request({
      url:'/comp/updateProfile',
      method:'post',
      data:profile

    })
  },
  removeImg(slideshow){
    return request({
      url:'/comp/updateSlideshow',
      method:'post',
      data:slideshow

    })
  },
  getOccInfo(queryInfo){
    return request({
      url:'/admin/getOccs',
      method:'get',
      params:queryInfo
    })
  },

  addOccInfos(occ){
    return request({
      url:`/admin/addOcc/${occ}`,
      method:'post',
    })

  },
  editOcc(updateOccDto){
    return request({
      url:'/admin/updateOcc',
      method:'post',
      data:updateOccDto
    })
  }



}
